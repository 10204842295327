.modal-dialog {
    .rider-img-container {
      position: relative;
      max-height: 250px;
      overflow: hidden;
    }
    
    .rider-img {
      display: block;
      width: 100%;
      height: auto;
    }
    
    .gradient-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(48, 48, 48, 0), rgb(48, 48, 48));
      pointer-events: none;
    }
  
    .modal-content {
      border: none;
      
      .modal-header {
        .btn-close {
          filter: invert(1); 
          background-color: rgb(167 167 167 / 75%) !important;
          border-radius: 50%;
          opacity: 1;
        }
        .btn-close:hover {
            background-color: rgba(160, 158, 158, 0.75) !important;
        }
      }

      .text-title {
        color: var(--bs-secondary-color) !important;
      }

      .text-desc {
        color: var(--bs-secondary-color) !important;
      }
  
      .flag-img {
        max-width: 56px;
        max-height: 42px;
        object-fit: cover;
      }
  
      .rider-img-container {
        position: relative;
        overflow: hidden;
  
        .gradient-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 50%;
          background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
        }
      }
  
      .detail-card {
        transition: transform 0.3s ease, box-shadow 0.3s ease;
  
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        }
      }
    }
  }