// menu.scss

#menu {
    position: fixed;
    top: 5%;
    right: 10px;
    z-index: 1000;
}

.menu-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #3498db;
    border-radius: 50%;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #2980b9;
    }
}

.menu-icon {
    color: #fff;
    font-size: 20px;
}
