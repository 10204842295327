table {
    thead {
        tr {
            th {
                color: rgb(255, 255, 255)!important;
            }
        }
    }
    tbody {
        tr {
            td {
                color: rgb(224, 224, 224)!important;
            }
        }
    }
    
}